import React from 'react';
import ScrollToTop from './components/ScrollToTop'
import Home from './pages/home'



// componennts 
import Header from './components/header'
import Footer from './components/footer'


function App() {
  return (
    <div className="wrapper">
      <Header/>
        <Home/>
      <Footer/>
    </div>
    );
}

export default App;
