import React, { Component } from 'react'
import $ from "jquery";
import {Link} from 'react-router-dom'


//animation
import Fade from 'react-reveal';
import Snowfall from 'react-snowfall'


import 'react-multi-carousel/lib/styles.css';




/* images */
import fotoproduct from './../../assets/produtos/image1.jpg'
import certs from './../../assets/produtos/certp.jpg'
import estadio from './../../assets/estadio.png'
import slb from  './../../assets/logos/slb.png'
import pggh from './../../assets/pggh.jpg'
import leftBrush from './../../assets/produtos/leftbrush.png'
import rightBrush from './../../assets/produtos/rightbrush.png'
import pkgmast from './../../assets/produtos/pkgmast.png'

import brushpkg from './../../assets/p-e.png'
import strawpkg from './../../assets/p-p.png'

/*imagens produtos */

import produto1 from './../../assets/produtos/escovas.png'
import produto2 from './../../assets/produtos/escovaskids.png'
import produto3 from './../../assets/produtos/straws.png'
import produto4 from './../../assets/produtos/cotonetes.png'


import conjunto1 from './../../assets/produtos/conjunto1.png'
import conjunto2 from './../../assets/produtos/conjunto2.png'
import conjunto3 from './../../assets/produtos/conjunto3.png'
import conjunto4 from './../../assets/produtos/conjunto4.png'
import conjunto5 from './../../assets/produtos/conjunto5.png'
import conjunto6 from './../../assets/produtos/conjunto6.png'



class Home extends Component {
    constructor(){
        super()
        this.state={
            produtos:[
                {name:"Escova de dentes adulto", img:produto1, link:"https://shop.babueco.com/produto/escovas-de-dentes-benfica-by-babu/"},
                {name:"Escova de dentes criança", img:produto2, link:"https://shop.babueco.com/produto/escovas-de-dentes-crianca-benfica-by-babu"},
                {name:"Kit palhinhas", img:produto3, link:"https://shop.babueco.com/produto/palhinhas-benfica-by-babu/"},
                {name:"Cotonetes", img:produto4, link:"https://shop.babueco.com/produto/cotonetes-benfica-by-babu/"},
            ],
            natal:[
                {name:"Família Benfiquista", img:conjunto1, link:"https://shop.babueco.com/produto/familia-gloriosa/", before:"15.96€", after:"13.57€"},
                {name:"Goleada na Luz", img:conjunto2, link:"https://shop.babueco.com/produto/grandes-goleadas",  before:"9.97€", after:"8.48€"},
                {name:"À Benfica",        img:conjunto3, link:"https://shop.babueco.com/produto/a-campeao/",        before:"5.98€", after:"5.08€"},
                {name:"Vitória garantida!",  img:conjunto4, link:"https://shop.babueco.com/produto/sabor-a-vitoria/",  before:"6.99€", after:"5.59€"},
                {name:"Benfiquista Glorioso",  img:conjunto5, link:"https://shop.babueco.com/produto/benfiquista-glorioso/",  before:"10.98€", after:"9.49€"},
                {name:"Benfica Campeão",  img:conjunto6, link:"https://shop.babueco.com/produto/benfica-campeao/",  before:"12.97€", after:"11.49€"},
            ],
        }
        this.handleForm = this.handleForm.bind(this)
        this.openForm = this.openForm.bind(this)
        this.handleAddition = this.handleAddition.bind(this)
    }

    componentDidMount() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }

    openForm(){
        this.setState({
            formactivation:!this.state.formactivation
        })
    }
    handleForm(e){
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: '',
        formactivation:!this.state.formactivation
        });
    
        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "newsletter.php",
        type: 'POST',
        data: {
            'email': this.state.email,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Foi adicionado à nossa lista!</h2></div>'
            });
            $('#containerText').slideUp();
            $('#containerText').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
    }

    handleAddition(e){
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

  render() {
//  style for first sec // style={{background: `url(${bgImg}) no-repeat center center`, backgroundSize:`cover`}}
    return (
        <main className="home_babu">
            <header id="home" className="hero masthead" style={{background: `url(${estadio}) no-repeat center center`,backgroundSize:`cover`}}>
                <div className="cta__ hero__container">
                    <Fade forever left >
                        <div className="cta__image">
                            <img src={pkgmast} alt="" />
                        </div>
                    </Fade>
                    <Fade forever right>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Existem remates que podem fazer toda a diferença</h2>
                                <div className="buttons-row">
                                    <a href="#produtos"><button className="button__babu">Comprar agora</button></a>
                                    <a href="#gloriosos"><button className="button__babu">Saber mais</button></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div> 
            </header>
            <section className="slb_logo">
                <div className="logo"><img src={slb} width="180" alt=""/></div>
                <div className="red"></div>
            </section>
            <section id="gloriosos" className="hero firstsec">
                <Fade forever left>
                    <div className="left-brush">
                        <img src={leftBrush} alt=""/>
                    </div>
                </Fade>
                <div className="principal">
                    <Fade forever >
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Gloriosas vitórias pela sustentabilidade</h2>
                                <p>
                                    Com o objetivo de voar mais alto neste campeonato da sustentabilidade, a Babu e o Sport Lisboa e Benfica lançaram a gama Benfica <em style={{fontStyle:'italic'}}>by</em> Babu para, todos juntos, alcançarmos vitórias pelo nosso planeta
                                </p>
                                <a href="#porque"><button className="button__babu">Saber mais</button></a>
                            </div>
                        </div>
                    </Fade>
                </div>
                 <Fade forever right>
                    <div className="right-brush">
                        <img src={rightBrush} alt=""/>
                    </div>
                </Fade>
            </section>
            <section id="porque" className="hero buysec">
                <div className="cta__">
                    <Fade forever bottom delay={50}>
                        <div className="cta__image">
                            <img src={fotoproduct} alt="" />
                        </div>
                    </Fade>
                    <Fade forever bottom delay={200}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Porquê escolher produtos em bambu?</h2>
                                <p>
                                    As características do bambu enquanto planta e as suas excelentes propriedades como material fazem dele a escolha acertada para dar resposta aos desafios ambientais dos dias de hoje.<br/>
                                    A espécie de bambu utilizada nos produtos Benfica <em style={{fontStyle:'italic'}}>by</em> Babu, <em style={{fontStyle:'italic'}}>Phyllostachys edulis</em>, trata-se de um recurso renovável de rápido crescimento, não requer fertilizantes, e não compromete a dieta dos animais que habitam as suas florestas, como é o caso dos pandas.
                                </p>
                                <div className="buttons-row">
                                    <a href="#produtos"><button className="button__babu">Comprar agora</button></a>
                                    <a href="https://babueco.com/porque"><button className="button__babu">Saber mais</button></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section id="certificados" className="hero certs">
                <div className="cta__">
                    <Fade forever bottom delay={50}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Certificações que valem golos</h2>
                                <p style={{marginBottom:"10px"}}>
                                    Todos os produtos Benfica <em style={{fontStyle:'italic'}}>by</em> Babu são certificados <em style={{fontStyle:'italic', fontWeight:"bold"}}>Vegan</em> pela <em style={{fontStyle:'italic', fontWeight:"bold"}}>Vegan Society</em> e <em style={{fontStyle:'italic', fontWeight:"bold"}}>Cruelty-free</em> pela PETA.org.
                                </p>
                                <p>
                                    Os parceiros de produção são certificados pela <strong>amfori BSCI</strong> por cumprirem critérios de satisfação do trabalho,
                                    bem como por várias outras entidades de certificação de qualidade e sustentabilidade,
                                    tais como:
                                    <ul className="list-cert">
                                        <li><strong>Certificado de Conformidade de RoHS</strong></li>
                                        <li><strong>Certificação FSC de Cadeia de Custódia</strong></li>
                                        <li><strong>Certificação de Sistemas de Gestão da Qualidade ISO 9001</strong></li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade forever bottom delay={100}>
                        <div className="cta__image">
                            <img src={certs} alt="" />
                        </div>
                    </Fade>
                </div>
            </section>
            <section id="conjuntos" className="itens-section">
                <Fade forever delay={100}>
                    <div className="title">
                        <h2>Os Conjuntos Benfica</h2>
                    </div>
                </Fade>
                <div className="item-list">
                    <Fade forever delay={300} cascade>
                        {this.state.natal.map((item, index)=>{
                                return(
                                    <div  key={index + "item"} className="items_container">
                                        <div className="items_content">
                                            <div className="item">
                                                <a target="_blank" href={item.link} >
                                                    <img src={item.img} alt=""/>
                                                    <div className="item_link">{item.name}</div>
                                                    <div className='discounts'><span className='scratchy'>{item.before}</span><span>{item.after}</span></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Fade>
                </div>
            </section>
            <section id="produtos" className="itens-section">
                <Fade forever delay={100}>
                    <div className="title">
                        <h2>Os produtos</h2>
                    </div>
                </Fade>
                <div className="item-list">
                    <Fade forever delay={300} cascade>
                        {this.state.produtos.map((item, index)=>{
                                return(
                                    <div  key={index + "item"} className="items_container">
                                        <div className="items_content">
                                            <div className="item">
                                                <a target="_blank" href={item.link} >
                                                    <img src={item.img} alt=""/>
                                                    <div className="item_link">{item.name}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Fade>
                </div>
            </section>
            <section id="babu" className="hero redbackground">
                <div className="blockup"></div>
                <div className="cta__">
                <Fade forever delay={300}><div className="cta__image"> <img className="diagonal--inverted" src={pggh} alt=""/></div></Fade>
                    <Fade forever delay={1000}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Pequenos gestos, grandes heróis!</h2>
                                <p>
                                    A Babu é uma marca portuguesa que nasceu da crença de que podemos e devemos criar soluções ecológicas para as atuais necessidades de todos nós,
                                    contribuindo assim para a mudança que todos desejamos.
                                </p>
                                <p>
                                    A missão da Babu vai muito além da produção dos produtos que a caracterizam.
                                    Ao escolher Babu, não está apenas a fazer uma escolha ecológica, está também a contribuir para todas as causas que a marca apoia, tais como a preservação ambiental,
                                    a conservação de espécies e a sensibilização para melhores hábitos entre as gerações mais jovens.
                                </p>
                                <div className="buttons-row">
                                    <a href="https://babueco.com/produtos"><button className="button__babu">Produtos Babu</button></a>
                                    <a href="https://babueco.com/sobre"><button className="button__babu">A Babu</button></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="blockdown"></div>

            </section>
            <section className="hero subscription">
                <Fade forever left>
                    <div className="cta__--white">
                        <div className="cta__containertext">
                            <div className="container__text" id="containerText">
                                <h2>
                                    Já subscreveu a nossa newsletter?
                                </h2>
                                <form className="news__sub" action="newsletter.php" onSubmit={this.handleForm}>
                                    <input type="email" name="email" onChange={this.handleAddition} placeholder="Insira o seu email" required/>
                                    <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>Li e compreendi a  <a href="https://shop.babueco.com/politica-privacidade/">Política de Privacidade</a> da Babu.</span></p></div>
                                    <button onSubmit={()=>this.handleForm()} className="button__babu">Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
      </main>
    )
  }
}

export default Home

