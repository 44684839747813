import React from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import './App.css'
import './fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import PT from './pt/App';


const cookieFunc= ()=>{
    let cookie = {
            allow: "Aceito os cookies",
            message: "Este website utiliza cookies estritamente necessários para o seu funcionamento ou dos serviços aqui prestados, mas também cookies de desempenho e cookies de segmentação que nos ajudam a melhorar a sua experiência e nos fornecem informações sobre a sua utilização e navegação no website. Com exceção dos cookies estritamente necessários, a utilização de cookies depende sempre da sua aceitação. ",
            dismiss: "",
            close: '&#x274c;',
            deny: "Não aceito",
            link: "Política de privacidade",
            href: "shop.babueco.com/politicadeprivacidade",
        }
        return cookie;
    }




class App extends React.Component {
    constructor(props){
        super(props)
        this.state={

        }
        this.fireCookieTranslate=this.fireCookieTranslate.bind(this)
    }

    componentWillUpdate(){
        this.fireCookieTranslate();
    }



    fireCookieTranslate(){
        window.cookieconsent.initialise({
            "palette": {
            "popup": {
                "background": "#ffffff",
                "text": "#333333"
            },
            "button": {
                "background": "#C30000",
                "text": "#ffffff"
            }
            },
            "theme": "classic",
            "type": "opt-out",
            "content": {
            "allow": cookieFunc().allow,
            "message": cookieFunc().message,
            "dismiss": "",
            "close": '&#x274c;',
            "deny": cookieFunc().deny,
            "link": cookieFunc().link,
            "href": cookieFunc().href,
            "target": '_blank',
            "policy": 'Cookies'
            }
        })
    }


render(){
  return (
    <section className="Start">
            <PT/>
            <script>
            {window.cookieconsent.initialise({
                "palette": {
                    "popup": {
                        "background": "#ffffff",
                        "text": "#333333"
                    },
                    "button": {
                        "background": "#C30000",
                        "text": "#ffffff"
                    }
                },
                "theme": "classic",
                "type": "opt-out",
                "content": {
                    "allow": cookieFunc().allow,
                    "message": cookieFunc().message,
                    "dismiss": "",
                    "close": '&#x274c;',
                    "deny": cookieFunc().deny,
                    "link": cookieFunc().link,
                    "href": cookieFunc().href,
                    "target": '_blank',
                    "policy": 'Cookies'
                }
            })}
            </script>
    </section>
    );
}
}

export default App;
