import React, { Component } from 'react'

import smf from './../../assets/icons/smf.png'
import smi from './../../assets/icons/smi.png'
import smy from './../../assets/icons/smy.png'


class Footer extends Component {
  render() {
    return (
    <footer className="footer">
          <div className="footer__container">
            <div className="terms">
              <div className="both">
                  <a href="https://babueco.com/perguntasfrequentes">Perguntas Frequentes</a>
                  <a target="_blank" rel="noopener noreferrer" href="https://blog.babueco.com/">Blog</a>
                  <a href="https://babueco.com/contactos">Contactos</a>
              </div>
            </div>
              <ul className="socialmedia">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BabuEco/"><img src={smf} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/babueco/"><img src={smi} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCafY2gShZXff-3whffG0Ihg"><img src={smy} alt=""/></a></li>
              </ul>
              <div className="terms">
              <div className="both">
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/politica-privacidade/">Politica de Privacidade</a>
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/termos-e-condicoes/">Termos e condições</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.livroreclamacoes.pt/">Livro de reclamações</a>

              </div>
            </div>
         </div>
         <div className="copyright">
          <p>
              © {(new Date().getFullYear())} S.L.B Produto Oficial <br/> © {(new Date().getFullYear())} Babu
          </p>
        </div>

    </footer>
    )
  }
}

export default Footer
