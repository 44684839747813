import React, {Component} from "react";
import Cookies from 'js-cookie'


/*Images*/ 
import conta from './../../assets/icons/icon-perfil.png'
import cart from './../../assets/icons/icon-carrinho.png'



/* Test Imports */ 


import logo from "./../../assets/logos/benficalogo.png"


class Header extends Component {
    constructor(props,context){
        super(props,context)
        this.state={
          openClose:false,
          lang:"",
        }
        this.openCloseMenu = this.openCloseMenu.bind(this);
      }

      langChange(e){
        let lang = e.target.value;
        this.setState({lang});
        localStorage.setItem("chosenlanguage", lang)
      }
    
      openCloseMenu(event){
        
        this.setState({openClose: !this.state.openClose})
        let body = document.getElementsByTagName('body')[0]
        body.classList.toggle('menu-open');
        if(body.classList.contains('menu-open') === this.state.openClose){
          body.classList.toggle('menu-open');
        }
      }
      
      catchingIndex(theindex){
        this.setState({indexservices:theindex})
      }
      
  render() {
    let url='https://blog.babueco.com';
    let item_count = Cookies.get('shopCartCount');

    return (

              <header className="menu">
              <nav className="navegacao">
                  <div className="navegacao__logo">
                      <a href="#home"><img src={logo} alt=""/></a>
                  </div>

                  <div className="navegacao__menus">
                    <div className="menus__perfil icon">
                        <a href="https://shop.babueco.com/minha-conta/">
                        <img src={conta} alt=""/>
                        </a>
                    </div>
                    <div className="menus__carrinho icon">
                        <a href="https://shop.babueco.com/carrinho/">
                        <img src={cart} alt=""/>
                        {item_count ? <span className="bolinha">{item_count}</span> : ""}
                        </a>
                    </div>
                      <div className="menus__hamburger" >
                          <div className="hamburger"   onClick={this.openCloseMenu} ><span></span></div>
                          <div className={ this.state.openClose ? "itens__hamburger opened" : "itens__hamburger"} onClick={this.openCloseMenu}>
                            <ul>
                                <a href="#produtos"><li className="menu-item">Comprar agora</li></a>
                                <a href="#gloriosos"><li className="menu-item">Benfica <em style={{fontStyle:'italic'}}>by</em> Babu</li></a>
                                <a href="#porque"><li className="menu-item">Porquê bambu?</li></a>
                                <a href="#certificados"><li className="menu-item">Certificações</li></a>
                                <a href="https://babueco.com"><li className="menu-item">A Babu</li></a>
                                <a href="https://www.slbenfica.pt/pt-pt/"><li className="menu-item">SLBenfica</li></a>
                            </ul>
                            <div className="filler"></div>
                          </div>
                      </div>
                  </div>
              </nav>
      </header> 

    )
  }
}


export default Header
